body {
  margin: 0;
  font-family: 'Eina02Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "Eina01Bold";
  src: local("Eina01Bold"),
   url(/static/media/Eina01_Bold.2b0b2bc0.otf) format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina01Regular";
  src: local("Eina01Regular"),
  url(/static/media/Eina01_Regular.12b1dde2.otf) format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina01Semibold";
  src: local("Eina01Semibold"),
    url(/static/media/Eina01_SemiBold.2f2f6d42.otf) format("truetype");
  font-weight: 600;
  }
@font-face {
  font-family: "Eina01RegularItalic";
  src: local("Eina01RegularItalic"),
    url(/static/media/Eina01_RegularItalic.bf3ba4e6.otf) format("truetype");
  font-weight: 400;
  }

@font-face {
  font-family: "Eina02Bold";
  src: local("Eina02Bold"),
    url(/static/media/Eina02_Bold.eebe1af3.otf) format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina02Regular";
  src: local("Eina02Regular"),
  url(/static/media/Eina02_Regular.9e73d1b7.otf) format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina02Semibold";
  src: local("Eina02Semibold"),
    url(/static/media/Eina02_SemiBold.a06828b6.otf) format("truetype");
  font-weight: 600;
  }
@font-face {
  font-family: "Eina02RegularItalic";
  src: local("Eina02RegularItalic"),
    url(/static/media/Eina02_RegularItalic.f40df5cb.otf) format("truetype");
  font-weight: 400;
  }

@font-face {
  font-family: "Eina03Bold";
  src: local("Eina03Bold"),
    url(/static/media/Eina03_Bold.f4a665a3.otf) format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina03Regular";
  src: local("Eina03Regular"),
  url(/static/media/Eina03_Regular.772b0d68.otf) format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina03Semibold";
  src: local("Eina03Semibold"),
    url(/static/media/Eina03_SemiBold.49dd5715.otf) format("truetype");
  font-weight: 600;
  }
  @font-face {
    font-family: "Eina03RegularItalic";
    src: local("Eina03RegularItalic"),
      url(/static/media/Eina03_RegularItalic.393c6041.otf) format("truetype");
    font-weight: 400;
    }

.App {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


body, html {
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 9;

  
}

@media(max-width: 720px) {
  .header-absolute {
    height: 70px;
  }
  
}


.full-height {
  height: 100%;
}
