body {
  margin: 0;
  font-family: 'Eina02Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "Eina01Bold";
  src: local("Eina01Bold"),
   url("./fonts/Eina/OpenType CFF Std/Eina01_Bold.otf") format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina01Regular";
  src: local("Eina01Regular"),
  url("./fonts/Eina/OpenType CFF Std/Eina01_Regular.otf") format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina01Semibold";
  src: local("Eina01Semibold"),
    url("./fonts/Eina/OpenType CFF Std/Eina01_SemiBold.otf") format("truetype");
  font-weight: 600;
  }
@font-face {
  font-family: "Eina01RegularItalic";
  src: local("Eina01RegularItalic"),
    url("./fonts/Eina/OpenType CFF Std/Eina01_RegularItalic.otf") format("truetype");
  font-weight: 400;
  }

@font-face {
  font-family: "Eina02Bold";
  src: local("Eina02Bold"),
    url("./fonts/Eina/OpenType CFF Std/Eina02_Bold.otf") format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina02Regular";
  src: local("Eina02Regular"),
  url("./fonts/Eina/OpenType CFF Std/Eina02_Regular.otf") format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina02Semibold";
  src: local("Eina02Semibold"),
    url("./fonts/Eina/OpenType CFF Std/Eina02_SemiBold.otf") format("truetype");
  font-weight: 600;
  }
@font-face {
  font-family: "Eina02RegularItalic";
  src: local("Eina02RegularItalic"),
    url("./fonts/Eina/OpenType CFF Std/Eina02_RegularItalic.otf") format("truetype");
  font-weight: 400;
  }

@font-face {
  font-family: "Eina03Bold";
  src: local("Eina03Bold"),
    url("./fonts/Eina/OpenType CFF Std/Eina03_Bold.otf") format("truetype");
  font-weight: 700;
  }
@font-face {
  font-family: "Eina03Regular";
  src: local("Eina03Regular"),
  url("./fonts/Eina/OpenType CFF Std/Eina03_Regular.otf") format("truetype");
  font-weight: 400;
  }
@font-face {
  font-family: "Eina03Semibold";
  src: local("Eina03Semibold"),
    url("./fonts/Eina/OpenType CFF Std/Eina03_SemiBold.otf") format("truetype");
  font-weight: 600;
  }
  @font-face {
    font-family: "Eina03RegularItalic";
    src: local("Eina03RegularItalic"),
      url("./fonts/Eina/OpenType CFF Std/Eina03_RegularItalic.otf") format("truetype");
    font-weight: 400;
    }
