.App {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


body, html {
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 9;

  
}

@media(max-width: 720px) {
  .header-absolute {
    height: 70px;
  }
  
}


.full-height {
  height: 100%;
}